import { Link } from "react-router-dom";
import Insta_icon from "../../../Assets/Instagram.png";
import Logo from "../../../Assets/Logo.avif";
import Tiktok_icon from "../../../Assets/Tiktok.png";
import Twitter_icon from "../../../Assets/Twitter.png";
import styles from "./Footer.module.scss";

function Footer() {
  return (
    <footer className={styles.mainContainer}>
      <div className={styles.footerTop}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={Logo} alt="logo" />
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p>© 2024, Junkfood Custom Arcades</p>
        <div className={styles.socialMediaContainer}>
          <Link className={styles.socialMediaIcon} to={"/"}>
            <img src={Tiktok_icon} alt="tiktok" />
          </Link>
          <Link className={styles.socialMediaIcon} to={"/"}>
            <img src={Insta_icon} alt="instagram" />
          </Link>
          <Link className={styles.socialMediaIcon} to={"/"}>
            <img src={Twitter_icon} alt="twitter" />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
