import toast from "react-hot-toast";

type toastType = "success" | "error";

export const toastify = (
  type: toastType,
  message: string,
  time: number = 3,
) => {
  if (type === "success") {
    toast.success(message, {
      style: {
        backgroundColor: "#f68f28",
        color: "#ffffff",
      },
      duration: time * 1000,
    });
  }

  if (type === "error") {
    toast.error(message, {
      style: {
        backgroundColor: "red",
        color: "#ffffff",
      },
    });
  }
};
