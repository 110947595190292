import { ReactComponent as CheckmarkIcon } from "../../../Assets/tick.svg";
import styles from "./Checkbox.module.scss";

interface Props {
  isActive: boolean;
}

function Checkbox(props: Props) {
  const { isActive = false } = props;

  return (
    <div
      className={isActive ? styles.mainContainerActive : styles.mainContainer}>
      <div className={styles.iconContainer}>
        <CheckmarkIcon className={styles.icon} />
      </div>
    </div>
  );
}

export default Checkbox;
