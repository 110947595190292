import { configureStore } from "@reduxjs/toolkit";
import { reducer as scrollbarReducer } from "./Scrollbar/Reducer";
import { reducer as authReducer } from "./Auth/Reducer";
import { reducer as contentReducer } from "./Content/Reducer";
import { reducer as languageReducer } from "./Language/Reducer";

export const store = configureStore({
  reducer: {
    scrollbar: scrollbarReducer,
    auth: authReducer,
    content: contentReducer,
    language: languageReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
