export type Animation = {
  id: number;
  title: string;
};

const animationList = [
  { id: 0, title: "All" },
  { id: 1, title: "Cylon" },
  { id: 2, title: "Cylon Wipe Redux" },
  { id: 3, title: "Rainbow" },
  { id: 4, title: "Breathing" },
  { id: 5, title: "Breathing Primary" },
  { id: 6, title: "This Or That" },
  { id: 7, title: "Heartbeat" },
  { id: 8, title: "Rainbow With Glitter" },
  { id: 9, title: "Juggle" },
  { id: 10, title: "Sinelon" },
  { id: 11, title: "BPM" },
  { id: 12, title: "Purple Rain" },
  { id: 13, title: "Steady Primary" },
];

export const getAnimationList = (): Array<Animation> => {
  return animationList;
};
