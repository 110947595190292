import useClick from "../../../Hooks/useClick";
import { toastify } from "../../../Utils/toastify";
import Bluetooth from "../Bluetooth/Bluetooth";
import Wifi from "../Wifi/Wifi";
import { ReactComponent as HelpQuestionIcon } from "../../../Assets/HelpQuestion.svg";
import styles from "./Firmware.module.scss";
import { Link } from "react-router-dom";

interface Props {
  firmwareVersion: string;
  latestFirmwareVersion: string;
  firmwareDownloadUrl: string;
  characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  wifiConnectionSuccessfull: null | boolean;
  setWifiConnectionSuccessfull: React.Dispatch<
    React.SetStateAction<null | boolean>
  >;
  isEditMode: boolean | null;
}

function Firmware(props: Props) {
  const {
    firmwareVersion,
    latestFirmwareVersion,
    characteristic,
    firmwareDownloadUrl,
    wifiConnectionSuccessfull,
    setWifiConnectionSuccessfull,
    isEditMode,
  } = props;

  const {
    isActive: isWifiModalActive,
    setIsActive: setIsWifiModalActive,
    ref: wifiModalRef,
  } = useClick(false);

  const {
    isActive: isBluetoothModalActive,
    setIsActive: setIsBluetoothModalActive,
    ref: bluetoothModalRef,
  } = useClick(false);

  const wifiFirmwareUpdateHandler = () => {
    if (characteristic) {
      if (typeof isEditMode === "boolean" && isEditMode === true) {
        setIsWifiModalActive(true);
      } else if (typeof isEditMode === "boolean" && isEditMode === false) {
        toastify("error", "Please Enable Edit Mode");
      }
    }
  };

  const bluetoothFirmwareUpdateHandler = async () => {
    if (characteristic) {
      if (typeof isEditMode === "boolean" && isEditMode === true) {
        setIsBluetoothModalActive(true);
      } else if (typeof isEditMode === "boolean" && isEditMode === false) {
        toastify("error", "Please Enable Edit Mode");
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.iconContainer}>
        <Link to={"/help"} target="_blank">
          <HelpQuestionIcon className={styles.icon} />
        </Link>
      </div>
      <div className={styles.headingContainer}>
        <h3 className={styles.heading}>Firmware</h3>
      </div>
      <div className={styles.firmwareContainer}>
        <div className={styles.versionContainer}>
          <p>Current Firmware Version</p>
          <p>{firmwareVersion}</p>
        </div>
        <div className={styles.updateContainer}>
          <p>Version Update Available</p>
          <p>
            {latestFirmwareVersion
              ?.replace(".bin", "")
              .replace("Prod_OTA_Firmware/firmware_", "")}
          </p>
        </div>
      </div>

      <div className={styles.descriptionContainer}>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
      </div>

      {/* {parseFloat(
        latestFirmwareVersion
          ?.replace(".bin", "")
          .replace("Prod_OTA_Firmware/firmware_", ""),
      ) > parseFloat(firmwareVersion) ? (
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={wifiFirmwareUpdateHandler}>
            Update by firmware by WIFI
          </button>
        </div>
      ) : null} */}

      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={wifiFirmwareUpdateHandler}>
          Update by firmware by WIFI
        </button>
      </div>

      {/* {parseFloat(
        latestFirmwareVersion
          ?.replace(".bin", "")
          .replace("Prod_OTA_Firmware/firmware_", ""),
      ) > parseFloat(firmwareVersion) &&
      parseFloat(firmwareVersion) >= 5.16 ? (
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={bluetoothFirmwareUpdateHandler}>
            Update by firmware by Bluetooth
          </button>
        </div>
      ) : null} */}

      <div className={styles.buttonContainer}>
        <button
          className={styles.button}
          onClick={bluetoothFirmwareUpdateHandler}>
          Update by firmware by Bluetooth
        </button>
      </div>

      <Wifi
        isActive={isWifiModalActive}
        setIsActive={setIsWifiModalActive}
        modalRef={wifiModalRef}
        characteristic={characteristic}
        firmwareDownloadUrl={firmwareDownloadUrl}
        wifiConnectionSuccessfull={wifiConnectionSuccessfull}
        setWifiConnectionSuccessfull={setWifiConnectionSuccessfull}
      />

      <Bluetooth
        isActive={isBluetoothModalActive}
        setIsActive={setIsBluetoothModalActive}
        modalRef={bluetoothModalRef}
        characteristic={characteristic}
        firmwareDownloadUrl={firmwareDownloadUrl}
      />
    </div>
  );
}

export default Firmware;
