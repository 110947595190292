import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Footer from "../Components/Common/Footer/Footer";
import Header from "../Components/Common/Header/Header";
import Connection from "../Pages/Connection/Connection";
import Home from "../Pages/Home/Home";
import Help from "../Pages/Help/Help";
import Login from "../Pages/Login/Login";
import AdminHeader from "../Components/Common/AdminHeader/AdminHeader";
import Contents from "../Pages/Contents/Contents";
import PrivateRoute from "../Components/Common/PrivateRoute/PrivateRoute";
import PublicRoute from "../Components/Common/PublicRoute/PublicRoute";

function Routes() {
  function Layout() {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  }

  function AdminLayout() {
    return (
      <>
        <AdminHeader />
        <Outlet />
      </>
    );
  }

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <PublicRoute element={<Home />} restricted={false} />,
        },

        {
          path: "/connection",
          element: <PublicRoute element={<Connection />} restricted={false} />,
        },

        {
          path: "/help",
          element: <PublicRoute element={<Help />} restricted={false} />,
        },
      ],
    },
    {
      path: "/admin/login",
      element: <PublicRoute element={<Login />} restricted={true} />,
    },

    {
      element: <AdminLayout />,
      children: [
        {
          path: "/admin/content",
          element: <PrivateRoute element={<Contents />} />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default Routes;
