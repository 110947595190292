import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ScanIcon } from "../../Assets/scan.svg";
import About from "../../Components/Connection/About/About";
import Animation from "../../Components/Connection/Animation/Animation";
import Brightness from "../../Components/Connection/Brightness/Brightness";
import Firmware from "../../Components/Connection/Firmware/Firmware";
import Led from "../../Components/Connection/Led/Led";
import Mapping from "../../Components/Connection/Mapping/Mapping";
import { Constants } from "../../Constants/Constants";
import { bluetoothConnectivity } from "../../Utils/bluetoothConnectivity";
import { bluetoothMessageIds } from "../../Utils/bluetoothMessageIds";
import { bluetoothReadFunctions } from "../../Utils/bluetoothReadFunctions";
import { isDeviceSupported } from "../../Utils/isDeviceSupported";
import styles from "./Connection.module.scss";

function Connection() {
  const navigate = useNavigate();
  const isSupported: boolean = true;
  // const isSupported: boolean = isDeviceSupported();
  const [activeTab, setActiveTab] = useState("about");
  const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);
  const [deviceConnected, setDeviceConnected] = useState<boolean>(false);
  const [device, setDevice] = useState<BluetoothDevice | undefined>(undefined);
  const [characteristic, setCharacteristic] = useState<
    BluetoothRemoteGATTCharacteristic | undefined
  >(undefined);

  // Global Values

  const [brightnessLevel, setBrightnessLevel] = useState(0);
  const [animationStatus, setAnimationStatus] = useState<boolean>(false);
  const [nonReactiveAnimation, setNonReactiveAnimation] =
    useState<boolean>(false);
  const [animationType, setAnimationType] = useState<number>(-1);
  const [primaryColor, setPrimaryColor] = useState<Array<number>>([]);
  const [secondaryColor, setSecondaryColor] = useState<Array<number>>([]);
  const [serialNo, setSerialNo] = useState<string>("");
  const [brookFirmwareVersion, setBrookFirmwareVersion] = useState<string>("");
  const [brookType, setBrookType] = useState<string>("");
  const [brookUuid, setBrookUuid] = useState<string>("");
  const [firmwareVersion, setFirmwareVersion] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState<boolean | null>(null);
  const [latestFirmwareVersion, setLatestFirmwareVersion] = useState("");
  const [firmwareDownloadUrl, setFirmwareDownloadUrl] = useState("");
  const [wifiConnectionSuccessfull, setWifiConnectionSuccessfull] = useState<
    null | boolean
  >(null);
  const [profile, setProfile] = useState<number>(0);
  const [boardButtons, setBoardButtons] = useState<Array<number>>([]);

  const getLatestFirmwareApi = async () => {
    try {
      const response = await fetch(`${Constants.apiEndpoint}/firmware`);
      const data = await response.json();
      setLatestFirmwareVersion(data?.version);
      setFirmwareDownloadUrl(data?.downloadUrl);
    } catch (error) {
      console.log("getLatestFirmwareApi error:", error);
    }
  };

  const getTabComponent = () => {
    switch (activeTab) {
      case "about":
        return (
          <About
            characteristic={characteristic}
            device={device?.name}
            firmwareVersion={firmwareVersion}
            serialNo={serialNo}
            brookFirmwareVersion={brookFirmwareVersion}
            brookType={brookType}
            isEditMode={isEditMode}
          />
        );

      case "brightness":
        return (
          <Brightness
            characteristic={characteristic}
            brightnessLevel={brightnessLevel}
            isEditMode={isEditMode}
          />
        );

      case "firmware":
        return (
          <Firmware
            firmwareVersion={firmwareVersion}
            latestFirmwareVersion={latestFirmwareVersion}
            characteristic={characteristic}
            firmwareDownloadUrl={firmwareDownloadUrl}
            wifiConnectionSuccessfull={wifiConnectionSuccessfull}
            setWifiConnectionSuccessfull={setWifiConnectionSuccessfull}
            isEditMode={isEditMode}
          />
        );

      case "animation":
        return (
          <Animation
            animationStatus={animationStatus}
            animationType={animationType}
            characteristic={characteristic}
            isEditMode={isEditMode}
            nonReactiveAnimation={nonReactiveAnimation}
          />
        );

      case "led":
        return (
          <Led
            characteristic={characteristic}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isEditMode={isEditMode}
          />
        );

      case "mapping":
        return (
          <Mapping
            characteristic={characteristic}
            brookUuid={brookUuid}
            profile={profile}
            setProfile={setProfile}
            boardButtons={boardButtons}
            setBoardButtons={setBoardButtons}
            isEditMode={isEditMode}
          />
        );

      default:
        break;
    }
  };

  const onDeviceDisconnected = () => {
    setDevice(undefined);
    setCharacteristic(undefined);
    setDeviceConnected(false);
    navigate(0);
  };

  let brookVersionValue = "";
  let brookVersionValueIndex = 0;

  const bluetoothNotificationHandler = (event: Event) => {
    const characteristic = event.target as BluetoothRemoteGATTCharacteristic;
    const value = characteristic.value;

    if (value) {
      let responseData = new Uint8Array(value?.buffer);

      // Brook UUID
      if (brookVersionValueIndex === responseData.length) {
        for (let i = 0; i < responseData.length - 1; i++) {
          const item = responseData[i];
          brookVersionValue += item
            ?.toString(16)
            .toUpperCase()
            .padStart(2, "0");
          setBrookUuid(brookVersionValue);
          brookVersionValue = "";
          brookVersionValueIndex = 0;
        }
      }

      // Brightness
      if (responseData[1] === bluetoothMessageIds.brightness) {
        if (responseData[3] === 0x31) {
          setBrightnessLevel(responseData[5]);
        }
      }
      // Animation Status
      if (responseData[1] === bluetoothMessageIds.animationStatus) {
        if (responseData[3] === 0x31) {
          if (responseData[5] === 1) {
            setAnimationStatus(true);
          }

          if (responseData[5] === 0) {
            setAnimationStatus(false);
          }
        }
      }

      // Non Reactive Animation
      if (responseData[1] === bluetoothMessageIds.nonReactiveAnimation) {
        if (responseData[3] === 0x31) {
          if (responseData[5] === 1) {
            setNonReactiveAnimation(true);
          }

          if (responseData[5] === 0) {
            setNonReactiveAnimation(false);
          }
        }
      }

      // Animation Type
      if (responseData[1] === bluetoothMessageIds.animationType) {
        if (responseData[3] === 0x31) {
          setAnimationType(responseData[5]);
        }
      }

      // Serial No
      if (responseData[1] === bluetoothMessageIds.serialNo) {
        if (responseData[3] === 0x31) {
          let returnValue = "";
          let array = responseData.slice(5, responseData[4] + 5);
          array.forEach((item) => {
            returnValue += item?.toString(16).toUpperCase().padStart(2, "0");
          });
          setSerialNo(returnValue);
        }
      }

      // Brook Firmware Version
      if (responseData[1] === bluetoothMessageIds.brookFirmware) {
        if (responseData[3] === 0x31) {
          let version = "";
          for (let i = 0; i < responseData[4]; i++) {
            const currentIndex = responseData[4] + 1 + i;
            version +=
              responseData[currentIndex].toString(16).toUpperCase() + ".";
          }
          setBrookFirmwareVersion(version);
        }
      }

      // Brook UUID
      if (responseData[1] === bluetoothMessageIds.brookUuid) {
        if (responseData[3] === 0x31) {
          let array = responseData.slice(5, responseData[4] + 5);
          array.forEach((item) => {
            brookVersionValue += item
              ?.toString(16)
              .toUpperCase()
              .padStart(2, "0");
          });

          if (array.length < responseData[4]) {
            brookVersionValueIndex = responseData[4] - array.length + 1;
          } else {
            setBrookFirmwareVersion(brookVersionValue);
            brookVersionValue = "";
          }
        }
      }

      // Brook Type
      if (responseData[1] === bluetoothMessageIds.brookProduct) {
        if (responseData[3] === 0x31) {
          let version = "";
          for (let i = 0; i < responseData[4]; i++) {
            const currentIndex = responseData[4] + 1 + i;
            version += responseData[currentIndex]
              .toString(16)
              .toUpperCase()
              .padStart(2, "0");
          }
          setBrookType(version);
        }
      }

      // Firmware Version
      if (responseData[1] === bluetoothMessageIds.firmwareVersion) {
        if (responseData[3] === 0x31) {
          let version = "";
          for (let i = 0; i < responseData[4]; i++) {
            const currentIndex = responseData[4] + i;
            version += String.fromCharCode(responseData[currentIndex]);
          }
          setFirmwareVersion(version);
        }
      }

      // Primary LED
      if (responseData[1] === bluetoothMessageIds.primaryLed) {
        if (responseData[3] === 0x31) {
          let rgbArray = responseData.slice(6, 9);
          setPrimaryColor(Array.from(rgbArray));
        }
      }

      // Secondary LED
      if (responseData[1] === bluetoothMessageIds.secondaryLed) {
        if (responseData[3] === 0x31) {
          let rgbArray = responseData.slice(6, 9);
          setSecondaryColor(Array.from(rgbArray));
        }
      }

      // WIFI Connection
      if (responseData[1] === bluetoothMessageIds.wifiConnection) {
        if (responseData[3] === 0x30) {
          if (responseData[5] === 0x6) {
            setWifiConnectionSuccessfull(true);
          }

          if (responseData[5] === 0x15) {
            setWifiConnectionSuccessfull(false);
          }
        }
      }

      // WIFI OTA
      if (responseData[1] === bluetoothMessageIds.startWifiOta) {
        if (responseData[3] === 0x31) {
        }
      }

      // WIFI Connection
      if (responseData[1] === bluetoothMessageIds.mappingButtons) {
        if (responseData[3] === 0x31) {
          let array: Array<number> = [];
          for (let i = 0; i < responseData[4] - 1; i++) {
            const element = responseData[i + 6];
            array.push(element);
          }

          setBoardButtons(array);
        }
      }

      if (responseData[2] === 0x02) {
        setIsEditMode(true);
      }

      if (responseData[2] === 0x82) {
        setIsEditMode(false);
      }
    }
  };

  const bluetoothHandler = async () => {
    const bluetooth = await bluetoothConnectivity();
    if (bluetooth) {
      setCharacteristic(bluetooth.characteristic);
      setDevice(bluetooth.device);
      setDeviceConnected(true);
    }
  };

  const bluetoothDisconnectHandler = () => {
    if (device) {
      device?.gatt?.disconnect();
    }
  };

  const deviceValueReadHandler = async () => {
    await bluetoothReadFunctions.getBrightnessLevel(characteristic);
    await bluetoothReadFunctions.getAnimationStatus(characteristic);
    await bluetoothReadFunctions.getNonReactiveAnimation(characteristic);
    await bluetoothReadFunctions.getAnimationType(characteristic);
    await bluetoothReadFunctions.getSerialNo(characteristic);
    await bluetoothReadFunctions.getBrookFirmwareVersion(characteristic);
    await bluetoothReadFunctions.getBrookProduct(characteristic);
    await bluetoothReadFunctions.getFirmwareVersion(characteristic);
    await bluetoothReadFunctions.getPrimaryLedColor(characteristic);
    await bluetoothReadFunctions.getSecondaryLedColor(characteristic);
    await bluetoothReadFunctions.getMappingButtons(characteristic, profile);
    await bluetoothReadFunctions.getBrookUuid(characteristic);
  };

  useEffect(() => {
    document.title = "Connection | Junkfood";
    isDeviceSupported();
  }, []);

  useEffect(() => {
    if (characteristic) {
      characteristic?.startNotifications();
      characteristic?.addEventListener(
        "characteristicvaluechanged",
        bluetoothNotificationHandler,
      );

      deviceValueReadHandler();
      getLatestFirmwareApi();
    }

    return () => {
      if (characteristic) {
        // characteristic?.stopNotifications();
        characteristic?.removeEventListener(
          "characteristicvaluechanged",
          bluetoothNotificationHandler,
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characteristic]);

  useEffect(() => {
    if (device) {
      device?.addEventListener("gattserverdisconnected", onDeviceDisconnected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  if (isSupported) {
    return (
      <div className={styles.mainContainer}>
        {deviceConnected ? (
          <>
            <div className={styles.disconnectButtonContainer}>
              <button
                className={styles.button}
                onClick={bluetoothDisconnectHandler}>
                Disconnect Device
              </button>
            </div>
            <div className={styles.settingsContainer}>
              <div className={styles.tabsContainer}>
                <div
                  className={
                    activeTab === "about" ? styles.tabActive : styles.tab
                  }
                  onClick={() => setActiveTab("about")}>
                  <button>About</button>
                </div>

                <div
                  className={
                    activeTab === "brightness" ||
                    activeTab === "led" ||
                    activeTab === "animation"
                      ? styles.tabActive
                      : styles.tab
                  }
                  onMouseEnter={() => setIsDropdownActive(true)}
                  onMouseLeave={() => setIsDropdownActive(false)}>
                  <button>
                    {activeTab === "brightness"
                      ? "Brightness"
                      : activeTab === "led"
                        ? "Color"
                        : activeTab === "animation"
                          ? "Animation"
                          : "Brightness"}
                  </button>
                  <div
                    className={
                      isDropdownActive
                        ? styles.dropdownContainerActive
                        : styles.dropdownContainer
                    }>
                    <button
                      onClick={() => {
                        setActiveTab("brightness");
                        setIsDropdownActive(false);
                      }}>
                      Brightness
                    </button>
                    <button
                      onClick={() => {
                        setActiveTab("led");
                        setIsDropdownActive(false);
                      }}>
                      Color
                    </button>
                    <button
                      onClick={() => {
                        setActiveTab("animation");
                        setIsDropdownActive(false);
                      }}>
                      Animation
                    </button>
                  </div>
                </div>

                <div
                  className={
                    activeTab === "firmware" ? styles.tabActive : styles.tab
                  }
                  onClick={() => setActiveTab("firmware")}>
                  <button>Firmware</button>
                </div>

                <div
                  className={
                    activeTab === "mapping" ? styles.tabActive : styles.tab
                  }
                  onClick={() => setActiveTab("mapping")}>
                  <button>Mapping</button>
                </div>
              </div>
              <div className={styles.bodyContainer}>{getTabComponent()}</div>
            </div>
          </>
        ) : (
          <div className={styles.scanContainer}>
            <div className={styles.topContainer}>
              <h2 className={styles.heading}>Connect To Micro</h2>
              <button
                className={styles.buttonContainer}
                onClick={bluetoothHandler}>
                Scan
                <div className={styles.iconContainer}>
                  <ScanIcon className={styles.icon} />
                </div>
              </button>
            </div>
            <div className={styles.bodyContainer}></div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.supportContainer}>
        <p className={styles.text}>
          Sorry, Your device is not supported, Please use Google Chrome or
          Microsoft Edge on Windows 10 or MacOs, Please make sure version of
          Chrome is greater than 78 or version of Edge is greater than 79.
        </p>
      </div>
    );
  }
}

export default Connection;
