import React from "react";
import { ReactComponent as LedBrightnessIcon } from "../../../Assets/LedBrightness.svg";
import { bluetoothCommands } from "../../../Utils/bluetoothCommands";
import { bluetoothReadFunctions } from "../../../Utils/bluetoothReadFunctions";
import { toastify } from "../../../Utils/toastify";
import styles from "./Brightness.module.scss";

interface Props {
  characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  brightnessLevel: number;
  isEditMode: boolean | null;
}

function Brightness(props: Props) {
  const { characteristic, brightnessLevel, isEditMode } = props;

  const mouseDragHandler = async (event: React.MouseEvent<HTMLDivElement>) => {
    try {
      if (typeof isEditMode === "boolean" && isEditMode === true) {
        const { left, width } = event.currentTarget.getBoundingClientRect();
        const localPer = ((event.clientX - left) / width) * 100;
        const newValue = Math.round(localPer);
        if (characteristic) {
          await characteristic.writeValue(
            bluetoothCommands.set.brightness(newValue),
          );

          await bluetoothReadFunctions.getBrightnessLevel(characteristic);
        }
      } else if (typeof isEditMode === "boolean" && isEditMode === false) {
        toastify("error", "Please Enable Edit Mode");
      }
    } catch (error) {
      console.log("mouseDragHandler", error);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <h4 className={styles.heading}>Adjust Yout MICRO LED Brightness</h4>
      </div>
      <div className={styles.controlContainer}>
        <div className={styles.iconContainer}>
          <LedBrightnessIcon className={styles.icon} />
        </div>
        <div className={styles.brightnessContainer}>
          <div
            className={styles.brightnessTrack}
            onMouseDown={mouseDragHandler}>
            <div
              className={styles.brightnessThumb}
              style={{ width: `${brightnessLevel}%` }}></div>
            <div
              className={styles.percentageContainer}
              style={{ left: `${brightnessLevel}%` }}>
              <p className={styles.percentage}>{brightnessLevel} %</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brightness;
