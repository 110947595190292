import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  language: string;
}

const initialState: InitialState = {
  language: "English",
};

const scrollbarSlice = createSlice({
  name: "scrollbar",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = scrollbarSlice.actions;
export const reducer = scrollbarSlice.reducer;
