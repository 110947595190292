import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  scrollbarActive: boolean | null;
}

const initialState: InitialState = {
  scrollbarActive: null,
};

const scrollbarSlice = createSlice({
  name: "scrollbar",
  initialState,
  reducers: {
    setScrollBar(state, action: PayloadAction<boolean>) {
      state.scrollbarActive = action.payload;
    },
  },
});

export const { setScrollBar } = scrollbarSlice.actions;
export const reducer = scrollbarSlice.reducer;
