import { Link } from "react-router-dom";
import { ReactComponent as ConnectToMicroIcon } from "../../../Assets/ConnectToMicro.svg";
import { ReactComponent as EventsIcon } from "../../../Assets/Event.svg";
import { ReactComponent as HelpIcon } from "../../../Assets/Help.svg";
import { ReactComponent as ProfileIcon } from "../../../Assets/Profile.svg";
import styles from "./MainMenu.module.scss";

function MainMenu() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>Main Menu</h1>
        <p className={styles.description}>
          Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem
          ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum
          lorem ipsum Lorem ipsum lorem ipsum
        </p>
      </div>

      <div className={styles.menusContainer}>
        <Link className={styles.menu} to={"/connection"}>
          <div className={styles.iconContainer}>
            <ConnectToMicroIcon className={styles.icon} />
          </div>
          <h4 className={styles.heading}>Connect To Micro</h4>
          <p className={styles.description}>
            Micro Moments, Infinite Connections
          </p>
          <p className={styles.button}>Connect To Micro</p>
        </Link>

        <Link className={styles.menu} to={"#"}>
          <div className={styles.iconContainer}>
            <ProfileIcon className={styles.icon} />
          </div>
          <h4 className={styles.heading}>Profiles (Coming Soon)</h4>
          <p className={styles.description}>
            Profiles Tailored To Your Potential
          </p>
          <p className={styles.button}>Profile</p>
        </Link>

        <Link className={styles.menu} to={"#"}>
          <div className={styles.iconContainer}>
            <EventsIcon className={styles.icon} />
          </div>
          <h4 className={styles.heading}>Events (Coming Soon)</h4>
          <p className={styles.description}>
            Exceptional Events, Endless Possibilties
          </p>
          <p className={styles.button}>Events</p>
        </Link>

        <Link className={styles.menu} to={"/help"}>
          <div className={styles.iconContainer}>
            <HelpIcon className={styles.icon} />
          </div>
          <h4 className={styles.heading}>Help</h4>
          <p className={styles.description}>Guiding You Towards Success</p>
          <p className={styles.button}>Help</p>
        </Link>
      </div>
    </div>
  );
}

export default MainMenu;
