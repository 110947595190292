import { detect } from "detect-browser";

export const isDeviceSupported = (): boolean => {
  const browser = detect();
  let isSupported = false;

  if (browser) {
    switch (browser?.os) {
      case "Windows 10":
        if (parseInt(browser?.version) > 78 && browser?.name === "chrome") {
          isSupported = true;
        }

        if (parseInt(browser?.version) > 79 && browser?.name === "edge") {
          isSupported = true;
        }

        break;

      case "Mac OS":
        if (parseInt(browser?.version) > 78 && browser?.name === "chrome") {
          isSupported = true;
        }

        if (parseInt(browser?.version) > 79 && browser?.name === "edge") {
          isSupported = true;
        }

        break;
    }
  }
  return isSupported;
};
