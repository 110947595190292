import React from "react";
import Spinner from "react-spinkit";
import styles from "./Loader.module.scss";

function Loader() {
  return (
    <div className={styles.mainContainer}>
      <Spinner name="circle" className={styles.loader} />
    </div>
  );
}

export default Loader;
