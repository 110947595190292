import { createSlice } from "@reduxjs/toolkit";
import { getAllContent } from "./Action";

interface InitialState {
  all_content: any;
  all_content_loading: boolean;
  all_content_error: any;
}

const initialState: InitialState = {
  all_content: null,
  all_content_loading: false,
  all_content_error: null,
};

const contentSlice = createSlice({
  name: "contentSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllContent.fulfilled, (state, action) => {
      state.all_content = action.payload;
      state.all_content_loading = false;
      state.all_content_error = null;
    });

    builder.addCase(getAllContent.pending, (state, action) => {
      state.all_content = null;
      state.all_content_loading = true;
      state.all_content_error = null;
    });

    builder.addCase(getAllContent.rejected, (state, action) => {
      state.all_content = null;
      state.all_content_loading = false;
      state.all_content_error = action.payload;
    });
  },
});

export const { reducer } = contentSlice;
