import { Constants } from "../../Constants/Constants";
import { deleteLocalStorage } from "../../Utils/deleteLocalStorage";
import { setAuthHeader } from "../../Utils/setAuthHeader";
import { setLocalStorage } from "../../Utils/setLocalStorage";
import { toastify } from "../../Utils/toastify";
import { AppDispatch } from "../store";
import { ActionTypes } from "./Types";
import axios from "axios";

export const loginUser =
  (email: string, password: string, successHandler: Function) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: ActionTypes.LOGIN_LOADING });
      const { data } = await axios.post(`${Constants.apiEndpoint}/auth/login`, {
        email,
        password,
      });

      dispatch({ type: ActionTypes.AUTH, payload: data });

      if (data?.success && data?.token) {
        await setLocalStorage("user", data);
        setAuthHeader(data?.token);
        toastify("success", "You are now logged in");
        successHandler();
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toastify("error", error?.response?.data?.message);
      } else {
        toastify("error", error?.message);
      }
      dispatch({
        type: ActionTypes.LOGIN_ERROR,
        payload: JSON.stringify(error),
      });
    }
  };

export const logoutUser = () => async (dispatch: AppDispatch) => {
  try {
    await deleteLocalStorage("user");
    setAuthHeader();
    toastify("success", "You are now logged out");
    dispatch({ type: ActionTypes.LOGOUT });
  } catch (error: any) {
    if (error?.response?.data?.message) {
      toastify("error", error?.response?.data?.message);
    } else {
      toastify("error", error?.message);
    }
  }
};

export const verifyUser = (user: any) => async (dispatch: AppDispatch) => {
  try {
    if (!user) {
      dispatch({ type: ActionTypes.VERIFY_USER, payload: null });
      return;
    }
    dispatch({ type: ActionTypes.VERIFY_USER_LOADING });
    const { data } = await axios.post(
      `${Constants.apiEndpoint}/auth/verify`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      },
    );

    if (data?.success === true) {
      setTimeout(() => {
        dispatch({ type: ActionTypes.VERIFY_USER, payload: user });
      }, 3000);
      setAuthHeader(user?.token);
    }
  } catch (error: any) {
    await deleteLocalStorage("user");
    if (error?.response?.data?.message) {
      toastify("error", error?.response?.data?.message);
    } else {
      toastify("error", error?.message);
    }
    dispatch({
      type: ActionTypes.VERIFY_USER_ERROR,
      payload: JSON.stringify(error),
    });
  }
};
