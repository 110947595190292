import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../../Assets/Close.svg";
import Logo from "../../../Assets/Logo.avif";
import { ReactComponent as MenuIcon } from "../../../Assets/Menu.svg";
import styles from "./AdminHeader.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { logoutUser } from "../../../Redux/Auth/Actions";

function AdminHeader() {
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const logoutHandler = () => {
    dispatch(logoutUser());
  };

  return (
    <header className={styles.mainContainer}>
      <Link to={"/"} className={styles.logoContainer}>
        <img className={styles.logo} src={Logo} alt="logo" />
      </Link>

      <div className={styles.mobileMenuContainer}>
        <button
          className={styles.buttonContainer}
          onClick={() => setMobileMenuActive(true)}>
          <MenuIcon className={styles.icon} />
        </button>

        <div
          className={`${styles.menuContainer} ${mobileMenuActive ? styles.menuContainerActive : null}`}>
          {/* <Link
            className={styles.menu}
            to={"/"}
            onClick={() => setMobileMenuActive(false)}>
            Home
          </Link>

          <Link
            className={styles.menu}
            to={"/connection"}
            onClick={() => setMobileMenuActive(false)}>
            Connection
          </Link> */}

          <button className={styles.menu} onClick={logoutHandler}>
            Logout
          </button>

          <button
            className={styles.closeButtonContainer}
            onClick={() => setMobileMenuActive(false)}>
            <CloseIcon className={styles.icon} />
          </button>
        </div>
      </div>

      <div className={styles.menusContainer}>
        <button className={styles.menu} onClick={logoutHandler}>
          Logout
        </button>
      </div>
    </header>
  );
}

export default AdminHeader;
