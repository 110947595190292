import React, { useEffect } from "react";
import styles from "./Help.module.scss";

function Help() {
  useEffect(() => {
    document.title = "Help | Junkfood";
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>Faqs</h1>
      </div>

      <div className={styles.faqsContainer}>
        <div className={styles.faq}>
          <div className={styles.questionContainer}>
            <p className={styles.label}>Question</p>
            <p className={styles.question}>Hold while plugging in for...</p>
          </div>

          <div className={styles.answerContainer}>
            <p className={styles.label}>Answer</p>
            <p className={styles.answer}>
              • Edit mode: Hold edit mode while plugging in to set the
              controller in a state to allow editing features with the app.
              <br /> • Emulation modes: Square/X = PS3 Triangle/Y = PS4 R1/RB =
              PC L1/LB = NS Cross/A = PS5 / XsX
              <br />• SOCD Modes (hold for 5 seconds after plug-in. Remembers
              the last choice, so no need to hold each time): Left = Classic
              (L+R=N &amp; U+D=U) Down = Second input priority Right = NONE UP =
              Capcom (L+R=N & U+D=N)
            </p>
          </div>
        </div>

        <div className={styles.faq}>
          <div className={styles.questionContainer}>
            <p className={styles.label}>Question</p>
            <p className={styles.question}>Firmware Updates</p>
          </div>

          <div className={styles.answerContainer}>
            <p className={styles.label}>Answer</p>
            <p className={styles.answer}>
              There are two chips on the controller. One that is updated from
              Brook (not as often) via PC and one that is updated by the phone
              app from JFA (more often). The PC download is on our website and
              works on both generations of MICROs (Gen 2 = July 2022 and later /
              Gen 1 = June 2022 and earlier). The app download is done through
              the MICRO by connecting it to a Wi-Fi network. This chip requires
              a 2.4 GHz network and will not work on a 5 GHz network. After the
              latest update is applied, the app will suggest 2.4 GHz networks
              that the MICRO can see for all future updates. More help and patch
              notes for firmware updates can be found in the "announcemnts"
              channel of our discord.
            </p>
          </div>
        </div>

        <div className={styles.faq}>
          <div className={styles.questionContainer}>
            <p className={styles.label}>Question</p>
            <p className={styles.question}>Cable Management</p>
          </div>

          <div className={styles.answerContainer}>
            <p className={styles.label}>Answer</p>
            <p className={styles.answer}>
              Don’t forget to use the hoops and the included hook-and-loop
              fastener to secure the cable to the MICRO.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
