import styles from "./FullPageLoader.module.scss";
import Spinner from "react-spinkit";

function FullPageLoader() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.loadingContainer}>
        <Spinner name="circle" className={styles.loader} />
      </div>
    </div>
  );
}

export default FullPageLoader;
