export const ActionTypes = {
  AUTH: "AUTH",
  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_ERROR: "LOGIN_ERROR",

  LOGOUT: "LOGOUT",

  VERIFY_USER: "VERIFY_USER",
  VERIFY_USER_LOADING: "VERIFY_USER_LOADING",
  VERIFY_USER_ERROR: "VERIFY_USER_ERROR",
};
