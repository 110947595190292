import { bluetoothMessageIds } from "./bluetoothMessageIds";

export const bluetoothCommands = {
  read: {
    brightness() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.brightness,
        0x01,
        0x00,
        0x23,
      );
    },

    animationStatus() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.animationStatus,
        0x01,
        0x00,
        0x23,
      );
    },

    nonReactiveAnimation() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.nonReactiveAnimation,
        0x01,
        0x00,
        0x23,
      );
    },

    animationType() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.animationType,
        0x01,
        0x00,
        0x23,
      );
    },

    serialNo() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.serialNo,
        0x01,
        0x00,
        0x23,
      );
    },

    brookFirmwareVersion() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.brookFirmware,
        0x01,
        0x00,
        0x23,
      );
    },

    brookProduct() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.brookProduct,
        0x01,
        0x00,
        0x23,
      );
    },

    brookUuid() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.brookUuid,
        0x01,
        0x00,
        0x23,
      );
    },

    firmwareVersion() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.firmwareVersion,
        0x01,
        0x00,
        0x23,
      );
    },

    primaryLedColor() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.primaryLed,
        0x01,
        0x00,
        0x23,
      );
    },

    secondaryLedColor() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.secondaryLed,
        0x01,
        0x00,
        0x23,
      );
    },

    wifiConnection() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.wifiConnection,
        0x01,
        0x00,
        0x23,
      );
    },

    startWifiOta() {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.startWifiOta,
        0x01,
        0x00,
        0x23,
      );
    },

    mappingButtons(profile: number) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.mappingButtons,
        0x02,
        0x00,
        profile,
        0x23,
      );
    },
  },

  set: {
    brightness(level: number) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.brightness,
        0x02,
        0x01,
        level,
        0x23,
      );
    },
    animationStatus(status: number) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.animationStatus,
        0x02,
        0x01,
        status,
        0x23,
      );
    },

    nonReactiveAnimation(status: number) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.nonReactiveAnimation,
        0x02,
        0x01,
        status,
        0x23,
      );
    },

    animationType(id: number) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.animationType,
        0x02,
        0x01,
        id,
        0x23,
      );
    },

    primaryColor(hex: Array<number>) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.primaryLed,
        0x05,
        0x01,
        0x00,
        ...hex,
        0x23,
      );
    },

    secondaryColor(hex: Array<number>) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.secondaryLed,
        0x05,
        0x01,
        0x00,
        ...hex,
        0x23,
      );
    },

    wifiSsid(ssid: Array<number>) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.wifiSsid,
        ssid?.length + 1,
        0x01,
        ...ssid,
        0x23,
      );
    },

    wifiPassword(password: Array<number>) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.wifiPassword,
        password?.length + 1,
        0x01,
        ...password,
        0x23,
      );
    },

    otaDownlloadUrl(url: Array<number>) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.otaDownloadUrl,
        url?.length + 3,
        0x01,
        0x01,
        0x01,
        ...url,
        0x23,
      );
    },

    otaHeader(fileSize: number, checksum: number) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.ota,
        0x0d,
        0x02,
        0 & 0xff,
        (0 >> 8) & 0xff,
        (0 >> 16) & 0xff,
        (0 >> 24) & 0xff,
        fileSize & 0xff,
        (fileSize >> 8) & 0xff,
        (fileSize >> 16) & 0xff,
        (fileSize >> 24) & 0xff,
        checksum & 0xff,
        (checksum >> 8) & 0xff,
        (checksum >> 16) & 0xff,
        (checksum >> 24) & 0xff,
        0x23,
      );
    },

    otaBody(chunk: Array<number>, packetSize: number) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.ota,
        chunk.length + 5,
        0x03,
        packetSize & 0xff,
        (packetSize >> 8) & 0xff,
        (packetSize >> 16) & 0xff,
        (packetSize >> 24) & 0xff,
        ...chunk,
        0x23,
      );
    },
    mappingButtons(profile: number, buttons: Array<number>) {
      return Uint8Array.of(
        0x24,
        bluetoothMessageIds.mappingButtons,
        buttons.length + 2,
        0x01,
        profile,
        ...buttons,
        0x23,
      );
    },
  },
};
