import useClick from "../../../Hooks/useClick";
import styles from "./Dropdown.module.scss";

interface Props {
  className?: string;
  onChange?: (value: string) => void;
  value: string | number | null;
  options?: Array<{
    label: string;
    value: string;
  }>;
}

function Dropdown(props: Props) {
  const { options, className, value, onChange } = props;

  const { isActive, ref, setIsActive } = useClick(false);

  const buttonLabelRenderer = (text: number | string | null) => {
    if (text === null) {
      return "Select a value";
    }

    if (text !== null && text !== undefined) {
      return text;
    }

    return " ";
  };

  return (
    <div
      className={`${styles.mainContainer} ${className ? className : null}`}
      ref={ref}>
      <button className={styles.button} onClick={() => setIsActive(!isActive)}>
        {buttonLabelRenderer(value)}
      </button>
      <div className={isActive ? styles.dropdownActive : styles.dropdown}>
        {options?.map((item, i) => {
          return (
            <button
              key={i}
              onClick={() => {
                setIsActive(false);
                if (onChange) {
                  onChange(item?.value);
                }
              }}>
              {item?.label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default Dropdown;
