import {
  hexToRgba,
  hsvaToHex,
  rgbaToHex,
  rgbaToHsva,
} from "@uiw/color-convert";
import ShadeSlider from "@uiw/react-color-shade-slider";
import Wheel from "@uiw/react-color-wheel";
import React, { useEffect, useState } from "react";
import { bluetoothCommands } from "../../../Utils/bluetoothCommands";
import { bluetoothReadFunctions } from "../../../Utils/bluetoothReadFunctions";
import { toastify } from "../../../Utils/toastify";
import styles from "./Led.module.scss";

interface Props {
  characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  primaryColor: Array<number>;
  secondaryColor: Array<number>;
  isEditMode: boolean | null;
}

function Led(props: Props) {
  const { characteristic, primaryColor, secondaryColor, isEditMode } = props;

  const [primaryHsva, setPrimaryHsva] = useState({ h: 0, s: 0, v: 99, a: 0 });
  const [secondaryHsva, setSecondaryHsva] = useState({
    h: 0,
    s: 0,
    v: 99,
    a: 0,
  });

  const [primaryHexCode, setPrimaryHexCode] = useState("");
  const [secondaryHexCode, setSecondaryHexCode] = useState("");

  // Primary
  const primaryHexChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPrimaryHexCode(e.target.value);
    if (e.target.value.length === 6) {
      let value = hexToRgba(e.target.value);
      let rgb = [value["r"], value["g"], value["b"]];

      await characteristic?.writeValue(bluetoothCommands.set.primaryColor(rgb));

      await bluetoothReadFunctions.getPrimaryLedColor(characteristic);
    }
  };

  const primarySliderChangeHandler = async (hsva: any) => {
    try {
      let hexValue = hsvaToHex(hsva).toUpperCase();
      setPrimaryHexCode(hexValue.replace("#", ""));
      let value = hexToRgba(hexValue);
      let rgb = [value["r"], value["g"], value["b"]];

      await characteristic?.writeValue(bluetoothCommands.set.primaryColor(rgb));
      await bluetoothReadFunctions.getPrimaryLedColor(characteristic);
    } catch (error) {
      console.log("primarySliderChangeHandler error :", error);
    }
  };

  const primaryWheelChangeHandler = async (hsva: any) => {
    try {
      let hexValue = hsvaToHex(hsva).toUpperCase();
      setPrimaryHexCode(hexValue.replace("#", ""));
      let value = hexToRgba(hexValue);
      let rgb = [value["r"], value["g"], value["b"]];

      await characteristic?.writeValue(bluetoothCommands.set.primaryColor(rgb));
      await bluetoothReadFunctions.getPrimaryLedColor(characteristic);
    } catch (error) {
      console.log("primarySliderChangeHandler error :", error);
    }
  };

  // Secondary
  const secondaryHexChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSecondaryHexCode(e.target.value);
    if (e.target.value.length === 6) {
      let value = hexToRgba(e.target.value);
      let rgb = [value["r"], value["g"], value["b"]];

      await characteristic?.writeValue(
        bluetoothCommands.set.secondaryColor(rgb),
      );

      await bluetoothReadFunctions.getSecondaryLedColor(characteristic);
    }
  };

  const secondarySliderChangeHandler = async (hsva: any) => {
    try {
      let hexValue = hsvaToHex(hsva).toUpperCase();
      setSecondaryHexCode(hexValue.replace("#", ""));
      let value = hexToRgba(hexValue);
      let rgb = [value["r"], value["g"], value["b"]];

      await characteristic?.writeValue(
        bluetoothCommands.set.secondaryColor(rgb),
      );
      await bluetoothReadFunctions.getSecondaryLedColor(characteristic);
    } catch (error) {
      console.log("secondarySliderChangeHandler error :", error);
    }
  };

  const secondaryWheelChangeHandler = async (hsva: any) => {
    try {
      let hexValue = hsvaToHex(hsva).toUpperCase();
      setSecondaryHexCode(hexValue.replace("#", ""));
      let value = hexToRgba(hexValue);
      let rgb = [value["r"], value["g"], value["b"]];

      await characteristic?.writeValue(
        bluetoothCommands.set.secondaryColor(rgb),
      );
      await bluetoothReadFunctions.getSecondaryLedColor(characteristic);
    } catch (error) {
      console.log("secondaryWheelChangeHandler error :", error);
    }
  };

  useEffect(() => {
    setPrimaryHexCode(
      rgbaToHex({
        r: primaryColor[0],
        g: primaryColor[1],
        b: primaryColor[2],
        a: 1,
      })
        .toUpperCase()
        .replace("#", ""),
    );

    setPrimaryHsva(
      rgbaToHsva({
        r: primaryColor[0],
        g: primaryColor[1],
        b: primaryColor[2],
        a: 1,
      }),
    );

    setSecondaryHexCode(
      rgbaToHex({
        r: secondaryColor[0],
        g: secondaryColor[1],
        b: secondaryColor[2],
        a: 1,
      })
        .toUpperCase()
        .replace("#", ""),
    );

    setSecondaryHsva(
      rgbaToHsva({
        r: secondaryColor[0],
        g: secondaryColor[1],
        b: secondaryColor[2],
        a: 1,
      }),
    );
  }, [primaryColor, secondaryColor]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>Choose your LED Colors</h2>
      </div>
      <div className={styles.colorPickerContainer}>
        <div className={styles.colorPicker}>
          <div className={styles.headingContainer}>
            <h4 className={styles.heading}>Primary Color</h4>
          </div>

          <div className={styles.wheelContainer}>
            <Wheel
              color={primaryHsva}
              onChange={(color) => {
                if (typeof isEditMode === "boolean" && isEditMode === true) {
                  setPrimaryHsva({ ...primaryHsva, ...color.hsva });
                  primaryWheelChangeHandler({ ...primaryHsva, ...color.hsva });
                } else if (
                  typeof isEditMode === "boolean" &&
                  isEditMode === false
                ) {
                  toastify("error", "Please Enable Edit Mode");
                }
              }}
            />
          </div>
          <div className={styles.shadeContainer}>
            <ShadeSlider
              hsva={primaryHsva}
              onChange={(newShade) => {
                if (typeof isEditMode === "boolean" && isEditMode === true) {
                  if (newShade.v < 100) {
                    setPrimaryHsva({ ...primaryHsva, ...newShade });
                    primarySliderChangeHandler({ ...primaryHsva, ...newShade });
                  }
                } else if (
                  typeof isEditMode === "boolean" &&
                  isEditMode === false
                ) {
                  toastify("error", "Please Enable Edit Mode");
                }
              }}
            />
          </div>
          <div className={styles.hexContainer}>
            <p>#</p>
            <input
              type="text"
              value={primaryHexCode}
              onChange={(e) => {
                if (typeof isEditMode === "boolean" && isEditMode === true) {
                  primaryHexChangeHandler(e);
                } else if (
                  typeof isEditMode === "boolean" &&
                  isEditMode === false
                ) {
                  toastify("error", "Please Enable Edit Mode");
                }
              }}
              maxLength={6}
              spellCheck={false}
              autoCorrect="none"
            />
          </div>
        </div>
        <div className={styles.colorPicker}>
          <div className={styles.headingContainer}>
            <h4 className={styles.heading}>Secondary Color</h4>
          </div>

          <div className={styles.wheelContainer}>
            <Wheel
              color={secondaryHsva}
              onChange={(color) => {
                if (typeof isEditMode === "boolean" && isEditMode === true) {
                  setSecondaryHsva({ ...secondaryHsva, ...color.hsva });
                  secondaryWheelChangeHandler({
                    ...secondaryHsva,
                    ...color.hsva,
                  });
                } else if (
                  typeof isEditMode === "boolean" &&
                  isEditMode === false
                ) {
                  toastify("error", "Please Enable Edit Mode");
                }
              }}
            />
          </div>
          <div className={styles.shadeContainer}>
            <ShadeSlider
              hsva={secondaryHsva}
              onChange={(newShade) => {
                if (typeof isEditMode === "boolean" && isEditMode === true) {
                  if (newShade.v < 100) {
                    setSecondaryHsva({ ...secondaryHsva, ...newShade });
                    secondarySliderChangeHandler({
                      ...secondaryHsva,
                      ...newShade,
                    });
                  }
                } else if (
                  typeof isEditMode === "boolean" &&
                  isEditMode === false
                ) {
                  toastify("error", "Please Enable Edit Mode");
                }
              }}
            />
          </div>
          <div className={styles.hexContainer}>
            <p>#</p>
            <input
              type="text"
              value={secondaryHexCode}
              onChange={(e) => {
                if (typeof isEditMode === "boolean" && isEditMode === true) {
                  secondaryHexChangeHandler(e);
                } else if (
                  typeof isEditMode === "boolean" &&
                  isEditMode === false
                ) {
                  toastify("error", "Please Enable Edit Mode");
                }
              }}
              maxLength={6}
              spellCheck={false}
              autoCorrect="none"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Led;
