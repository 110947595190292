export const findDuplicateButtons = (
    arr: number[],
  ): { hasDuplicates: boolean; duplicateIndexes: number[] } => {
    const seen = new Map<number, number>();
    const duplicateIndexes: number[] = [];
    let hasDuplicates = false;

    for (let i = 0; i < arr.length; i++) {
      const current = arr[i];
      if (seen.has(current)) {
        duplicateIndexes.push(i);
        const firstIndex = seen.get(current)!;
        duplicateIndexes.push(firstIndex);
        hasDuplicates = true;
        break;
      } else {
        seen.set(current, i);
      }
    }

    return {
      hasDuplicates,
      duplicateIndexes,
    };
  };