import { useEffect } from "react";
import Hero from "../../Components/Home/Hero/Hero";
import MainMenu from "../../Components/Home/MainMenu/MainMenu";
import styles from "./Home.module.scss";

function Home() {
  useEffect(() => {
    document.title = "Home | Junkfood";
  }, []);

  return (
    <div className={styles.mainContainer}>
      <Hero />
      <MainMenu />
    </div>
  );
}

export default Home;
