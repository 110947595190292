import { Link } from "react-router-dom";
import BoardImage from "../../../Assets/Board.png";
import styles from "./Hero.module.scss";

function Hero() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.infoContainer}>
        <p className={styles.subHeading}>WELCOME TO JUNKFOOD</p>
        <h1 className={styles.heading}>
          Get Ready To Enjoy Razor Games With Our Platform
        </h1>
        <p className={styles.description}>
          Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem
          ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum
          lorem ipsum Lorem ipsum lorem ipsum
        </p>
        <Link className={styles.menu} to={"/connection"}>
          Connection
        </Link>
      </div>
      <div className={styles.imageContainer}>
        <img src={BoardImage} alt="board" className={styles.image} />
      </div>
    </div>
  );
}

export default Hero;
