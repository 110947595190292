import { bluetoothCommands } from "../../../Utils/bluetoothCommands";
import { bluetoothReadFunctions } from "../../../Utils/bluetoothReadFunctions";
import { getAnimationList } from "../../../Utils/getAnimationList";
import { toastify } from "../../../Utils/toastify";
import Checkbox from "../../Common/Checkbox/Checkbox";
import Switch from "../../Common/Switch/Switch";
import styles from "./Animation.module.scss";

interface Props {
  animationType: number;
  animationStatus: boolean;
  nonReactiveAnimation: boolean;
  characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  isEditMode: boolean | null;
}

function Animation(props: Props) {
  const {
    animationStatus,
    characteristic,
    animationType,
    isEditMode,
    nonReactiveAnimation,
  } = props;

  const animationStatusHandler = async () => {
    if (characteristic) {
      if (typeof isEditMode === "boolean" && isEditMode === true) {
        if (animationStatus === true) {
          await characteristic.writeValue(
            bluetoothCommands.set.animationStatus(0x00),
          );
        }

        if (animationStatus === false) {
          await characteristic.writeValue(
            bluetoothCommands.set.animationStatus(0x01),
          );
        }

        await bluetoothReadFunctions.getAnimationStatus(characteristic);
      } else if (typeof isEditMode === "boolean" && isEditMode === false) {
        toastify("error", "Please Enable Edit Mode");
      }
    }
  };

  const nonReactiveAnimationHandler = async () => {
    if (characteristic) {
      if (typeof isEditMode === "boolean" && isEditMode === true) {
        if (nonReactiveAnimation === true) {
          await characteristic.writeValue(
            bluetoothCommands.set.nonReactiveAnimation(0x00),
          );
        }

        if (nonReactiveAnimation === false) {
          await characteristic.writeValue(
            bluetoothCommands.set.nonReactiveAnimation(0x01),
          );
        }

        await bluetoothReadFunctions.getNonReactiveAnimation(characteristic);
      } else if (typeof isEditMode === "boolean" && isEditMode === false) {
        toastify("error", "Please Enable Edit Mode");
      }
    }
  };

  const animationTypeHandler = async (id: number) => {
    if (characteristic) {
      if (typeof isEditMode === "boolean" && isEditMode === true) {
        if (id !== animationType) {
          await characteristic.writeValue(
            bluetoothCommands.set.animationType(id),
          );
          await bluetoothReadFunctions.getAnimationType(characteristic);
        }
      } else if (typeof isEditMode === "boolean" && isEditMode === false) {
        toastify("error", "Please Enable Edit Mode");
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.bodyContainer}>
        <div className={styles.headingContainer}>
          <h4 className={styles.heading}>
            Turn Animation Off/On And Select A Sequence
          </h4>
        </div>

        <Switch
          label="Turn Off/On"
          isActive={animationStatus}
          style={{ marginTop: "20px" }}
          onClickHandler={animationStatusHandler}
        />

        <Switch
          label="Button Press Animation"
          isActive={nonReactiveAnimation}
          style={{ marginTop: "20px" }}
          onClickHandler={nonReactiveAnimationHandler}
        />

        <div className={styles.animationContainer}>
          <div className={styles.headingContainer}>
            <div className={styles.heading}>Choose an Animation</div>
          </div>
          <div className={styles.animationListContainer}>
            {getAnimationList().map((item, i: number) => {
              return (
                <div
                  key={i}
                  className={styles.list}
                  onClick={() => animationTypeHandler(item?.id)}>
                  <div className={styles.checkboxContainer}>
                    <Checkbox isActive={animationType === item?.id} />
                  </div>
                  <div className={styles.title}>{item?.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Animation;
