export const Constants = {
  bluetooth: {
    serviceId: "4fafc201-1fb5-459e-8fcc-c5c9c331914b",
    characteristicsId: "beb5483e-36e1-4688-b7f5-ea07361b26a8",
  },

  apiEndpoint:
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000"
      : "https://junkfood-backend.vercel.app",
};
