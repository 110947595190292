import { Constants } from "../Constants/Constants";
import { toastify } from "./toastify";

export const bluetoothConnectivity = async () => {
  try {
    if ("bluetooth" in navigator) {
      const isAvilable = await navigator?.bluetooth?.getAvailability();
      console.log("isAvilable", isAvilable);
      if (isAvilable) {
        const device = await navigator.bluetooth.requestDevice({
          filters: [{ services: [Constants.bluetooth.serviceId] }],
        });
        const server = await device?.gatt?.connect();
        const service = await server?.getPrimaryService(
          Constants.bluetooth.serviceId,
        );
        const characteristic = await service?.getCharacteristic(
          Constants.bluetooth.characteristicsId,
        );

        return { characteristic, device };
      } else {
        toastify(
          "error",
          "Bluetooth device not found, please enable web bluetooth experimental feature in chrome://flags/",
        );
        return;
      }
    } else {
      console.log("not found");
      toastify(
        "error",
        "Bluetooth device not found, please enable web bluetooth experimental feature in chrome://flags/",
      );
      return;
    }
  } catch (error) {
    console.log("error", error);
    return;
  }
};
