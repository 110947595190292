import styles from "./About.module.scss";

interface Props {
  characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  device: string | undefined;
  firmwareVersion: string;
  serialNo: string;
  brookFirmwareVersion: string;
  brookType: string;
  isEditMode: boolean | null;
}

function About(props: Props) {
  const {
    device,
    firmwareVersion,
    serialNo,
    isEditMode,
    brookFirmwareVersion,
    brookType,
  } = props;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>About This MICRO</h2>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <p>Device Name</p>
          <p>{device}</p>
        </div>
        <div className={styles.info}>
          <p>Brook Version</p>
          <p>{brookFirmwareVersion}</p>
        </div>
        <div className={styles.info}>
          <p>Brook Type</p>
          <p>{brookType}</p>
        </div>
        <div className={styles.info}>
          <p>Serial Number</p>
          <p>{serialNo}</p>
        </div>
        <div className={styles.info}>
          <p>JFA Firmware Version</p>
          <p>{firmwareVersion}</p>
        </div>
        <div className={styles.info}>
          <p>App Version</p>
          <p>1.0</p>
        </div>
        <div className={styles.info}>
          <p>Edit Mode</p>
          <p>
            {typeof isEditMode === "boolean" && isEditMode === true
              ? "Enabled"
              : typeof isEditMode === "boolean" && isEditMode === false
                ? "Not Enabled"
                : null}
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
