import { useEffect, useState } from "react";
import Spinner from "react-spinkit";
import { bluetoothCommands } from "../../../Utils/bluetoothCommands";
import { convertStringToAscii } from "../../../Utils/convertStringToAscii";
import { toastify } from "../../../Utils/toastify";
import styles from "./Wifi.module.scss";

interface Props {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  modalRef: React.MutableRefObject<any>;
  characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  firmwareDownloadUrl: string;
  wifiConnectionSuccessfull: null | boolean;
  setWifiConnectionSuccessfull: React.Dispatch<
    React.SetStateAction<null | boolean>
  >;
}

function Wifi(props: Props) {
  const {
    modalRef,
    isActive,
    characteristic,
    firmwareDownloadUrl,
    wifiConnectionSuccessfull,
    setWifiConnectionSuccessfull,
  } = props;

  const [wifiSsid, setWifiSsid] = useState<string>("MA-Connect");
  const [wifiPassword, setWifiPassword] = useState<string>("matech321");
  const [loading, setLoading] = useState<boolean>(false);

  const firmwareUpdateHandler = async () => {
    setLoading(true);
    try {
      if (wifiSsid && wifiPassword) {
        let wifiSsidAscii = convertStringToAscii(wifiSsid);

        await characteristic?.writeValue(
          bluetoothCommands.set.wifiSsid(wifiSsidAscii),
        );

        let wifiPasswordAscii = convertStringToAscii(wifiPassword);

        await characteristic?.writeValue(
          bluetoothCommands.set.wifiPassword(wifiPasswordAscii),
        );

        await characteristic?.writeValue(
          bluetoothCommands.read.wifiConnection(),
        );
      } else {
        setLoading(false);
        toastify("error", "Wifi SSID and Password is required");
      }
    } catch (error) {
      console.log("firmwareUpdateHandler error:", error);
    }
  };

  const startFirmwareUpgrade = async () => {
    if (
      typeof wifiConnectionSuccessfull === "boolean" &&
      wifiConnectionSuccessfull === true
    ) {
      toastify(
        "success",
        "WIFI Connection was successfull, Now firmware upgrade will start, Please dont disconnect or unplug the device, the progress will be indicated through board leds, let it finish and connect again",
        8,
      );
      setTimeout(async () => {
        try {
          let otaDownloadUrlAscii = convertStringToAscii(firmwareDownloadUrl);

          await characteristic?.writeValue(
            bluetoothCommands.set.otaDownlloadUrl(otaDownloadUrlAscii),
          );

          await characteristic?.writeValue(
            bluetoothCommands.read.startWifiOta(),
          );
          setLoading(false);
          setWifiConnectionSuccessfull(null);
        } catch (error) {
          console.log("setTimeout error", error);
        }
      }, 8000);
    } else if (
      typeof wifiConnectionSuccessfull === "boolean" &&
      wifiConnectionSuccessfull === false
    ) {
      toastify(
        "error",
        "WIFI connection was unsuccessfull, Please confirm the WIFI is working and credentials are correct",
      );
      setLoading(false);
      setWifiConnectionSuccessfull(null);
    }
  };

  useEffect(() => {
    startFirmwareUpgrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wifiConnectionSuccessfull]);

  return (
    <div
      className={`${styles.mainContainer} ${isActive ? styles.mainContainerActive : null}`}
      ref={modalRef}>
      <div className={styles.headingContainer}>
        <p className={styles.heading}>Wifi Information</p>
      </div>
      <div className={styles.inputContainer}>
        <p className={styles.label}>WIFI SSID</p>
        <input
          className={styles.input}
          type="text"
          value={wifiSsid}
          onChange={(e) => setWifiSsid(e.target.value)}
        />
      </div>

      <div className={styles.inputContainer}>
        <p className={styles.label}>WIFI Password</p>
        <input
          className={styles.input}
          type="text"
          value={wifiPassword}
          onChange={(e) => setWifiPassword(e.target.value)}
        />
      </div>

      {loading ? (
        <div className={styles.loadingContainer}>
          <Spinner name="circle" className={styles.loader} />
        </div>
      ) : null}

      <div className={styles.buttonContainer}>
        <button onClick={firmwareUpdateHandler} className={styles.button}>
          Update
        </button>
      </div>
    </div>
  );
}

export default Wifi;
