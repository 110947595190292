import { useEffect, useRef, useState } from "react";
export default function useClick(initialIsVisible: boolean) {
  const [isActive, setIsActive] = useState(initialIsVisible);
  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref?.current && !ref?.current?.contains(event.target)) {
      setIsActive(false);
    } else {
      //   setIsActive(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return { ref, isActive, setIsActive };
}
