import React from "react";
import styles from "./Switch.module.scss";

interface Props {
  isActive: boolean;
  style: React.CSSProperties;
  label: string;
  onClickHandler?: () => void;
}

function Switch(props: Props) {
  const { isActive = false, label, style, onClickHandler } = props;

  return (
    <div
      className={styles.mainContainer}
      style={style}
      onClick={() => {
        if (onClickHandler) {
          onClickHandler();
        }
      }}>
      <div
        className={
          isActive ? styles.switchContainerActive : styles.switchContainer
        }>
        <div className={styles.switch}>
          <p>{label}</p>
          <div className={styles.thumbContainer}>
            <div className={styles.thumb}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Switch;
