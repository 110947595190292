import { createAsyncThunk } from "@reduxjs/toolkit";
import { Constants } from "../../Constants/Constants";
import axios from "axios";

export const getAllContent = createAsyncThunk(
  "getAllContent",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${Constants.apiEndpoint}/content/get-all`,
      );

      return data;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return rejectWithValue(error?.response?.data);
    }
  },
);
