import { useDispatch, useSelector } from "react-redux";
import { verifyUser } from "./Redux/Auth/Actions";
import Routes from "./Routes/Routes";
import { readLocalStorage } from "./Utils/readLocalStorage";
import { AppDispatch, RootState } from "./Redux/store";
import { useEffect } from "react";
import FullPageLoader from "./Components/Common/FullPageLoader/FullPageLoader";
import { setLanguage } from "./Redux/Language/Reducer";

function App() {
  const dispatch = useDispatch<AppDispatch>();

  const { verify_user_loading } = useSelector((state: RootState) => state.auth);

  const authHandler = async () => {
    const user = await readLocalStorage("user");
    const lang = await readLocalStorage("language");
    if (lang) {
      dispatch(setLanguage(lang));
    }
    dispatch(verifyUser(user));
  };

  useEffect(() => {
    authHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (verify_user_loading) {
    return <FullPageLoader />;
  }
  return <Routes />;
}

export default App;
