import { useState } from "react";
import useClick from "../../../Hooks/useClick";
import { bluetoothCommands } from "../../../Utils/bluetoothCommands";
import { bluetoothReadFunctions } from "../../../Utils/bluetoothReadFunctions";
import { getBoard } from "../../../Utils/getBoard";
import { Profile, getProfiles } from "../../../Utils/getProfiles";
import { toastify } from "../../../Utils/toastify";
import SnackBoxMicro from "../../Boards/SnackBoxMicro/SnackBoxMicro";
import Button from "../Button/Button";
import styles from "./Mapping.module.scss";
import { findDuplicateButtons } from "../../../Utils/findDuplicatesButtons";

interface Props {
  characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  brookUuid: string | undefined;
  profile: number;
  setProfile: React.Dispatch<React.SetStateAction<number>>;
  boardButtons: Array<number>;
  setBoardButtons: React.Dispatch<React.SetStateAction<number[]>>;
  isEditMode: boolean | null;
}

function Mapping(props: Props) {
  const {
    characteristic,
    brookUuid,
    profile,
    setProfile,
    boardButtons,
    setBoardButtons,
    isEditMode,
  } = props;

  const { ref, isActive, setIsActive } = useClick(false);

  const [activeButton, setActiveButton] = useState(-1);

  const board = getBoard(brookUuid);

  const profileHandler = async (data: Profile) => {
    try {
      if (profile !== data?.id) {
        setProfile(data?.id);
        setIsActive(false);
        await bluetoothReadFunctions.getMappingButtons(
          characteristic,
          data?.id,
        );
      } else {
        setIsActive(false);
      }
    } catch (error) {
      console.log("profileHandler error", error);
    }
  };

  const saveChangesHandler = async () => {
    try {
      if (typeof isEditMode === "boolean" && isEditMode === true) {
        if (profile === 0) {
          toastify(
            "error",
            "Profile 0 cannot be modified, Please choose any other",
          );
        } else {
          const { duplicateIndexes, hasDuplicates } =
            findDuplicateButtons(boardButtons);
          if (hasDuplicates) {
            toastify(
              "error",
              `Button ${duplicateIndexes[0]} and Button ${duplicateIndexes[1]} have same keys`,
            );
          } else {
            await characteristic?.writeValue(
              bluetoothCommands.set.mappingButtons(profile, boardButtons),
            );
            toastify("success", `Buttons are mapped to Profile ${profile}`);
          }
        }
      } else if (typeof isEditMode === "boolean" && isEditMode === false) {
        toastify("error", "Please Enable Edit Mode");
      }
    } catch (error) {
      console.log("saveChangesHandler error:", error);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.sidebarContainer}>
        <div className={styles.dropdownContainer} ref={ref}>
          <button
            className={styles.button}
            onClick={() => setIsActive(!isActive)}>
            Profile {profile}
          </button>
          <div className={isActive ? styles.dropdownActive : styles.dropdown}>
            {getProfiles().map((item, i) => {
              return (
                <button key={i} onClick={() => profileHandler(item)}>
                  {item?.label}
                </button>
              );
            })}
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          {board?.buttons?.map((item, i) => {
            return (
              <Button
                key={i}
                data={item}
                label={`Button ${i} ( ${boardButtons[item?.id]} )`}
                totalButtons={board?.buttons?.length}
                setActiveButton={setActiveButton}
                boardButtons={boardButtons}
                
                setBoardButtons={setBoardButtons}
              />
            );
          })}
        </div>

        <div className={styles.saveContainer}>
          <button className={styles.button} onClick={saveChangesHandler}>
            Save Changes
          </button>
        </div>
      </div>
      <div className={styles.boardContainer}>
        <SnackBoxMicro activeButton={activeButton} />
      </div>
    </div>
  );
}

export default Mapping;
