export type Profile = {
  id: number;
  label: string;
};

const profiles: Array<Profile> = [
  {
    id: 0,
    label: "Profile 0",
  },
  {
    id: 1,
    label: "Profile 1",
  },
  {
    id: 2,
    label: "Profile 2",
  },
  {
    id: 3,
    label: "Profile 3",
  },
  {
    id: 4,
    label: "Profile 4",
  },
  {
    id: 5,
    label: "Profile 5",
  },
  {
    id: 6,
    label: "Profile 6",
  },
  {
    id: 7,
    label: "Profile 7",
  },
];

export const getProfiles = (): Array<Profile> => {
  return profiles;
};
