import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../../Redux/store";

interface PublicRouteProps {
  element: JSX.Element;
  restricted: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element, restricted }) => {
  const { auth } = useSelector((state: RootState) => state.auth);

  if (auth && restricted) {
    return <Navigate to="/admin/content" replace />;
  }

  return element;
};

export default PublicRoute;
