import React, { useEffect, useState } from "react";
import styles from "./Login.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { loginUser } from "../../Redux/Auth/Actions";
import { toastify } from "../../Utils/toastify";
import { isEmail } from "../../Utils/isEmail";
import Spinner from "react-spinkit";
import { useNavigate } from "react-router-dom";

function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("test@gmail.com");
  const [password, setPassword] = useState<string>("test12345");

  const { auth, login_loading } = useSelector((state: RootState) => state.auth);
  console.log("auth", auth);

  const loginHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();

    const successHandler = () => {
      console.log("success handler");
      navigate("/admin/content", { replace: true });
    };

    if (!login_loading) {
      if (email && password) {
        if (isEmail(email)) {
          dispatch(loginUser(email, password, successHandler));
        } else {
          toastify("error", "Invalid email");
        }
      } else {
        toastify("error", "Please enter all required fields");
      }
    }
  };

  useEffect(() => {
    document.title = "Login | Junkfood";
  }, []);

  return (
    <div className={styles.mainContainer}>
      <form className={styles.formContainer}>
        <div className={styles.loginContainer}>
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Login</h1>
          </div>
          <div className={styles.inputContainer}>
            <p className={styles.label}>Email</p>
            <input
              className={styles.input}
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className={styles.inputContainer}>
            <p className={styles.label}>Password</p>
            <input
              className={styles.input}
              type="text"
              placeholder="Password"
              value={[password]}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          {login_loading ? (
            <div className={styles.loadingContainer}>
              <Spinner name="circle" className={styles.loader} />
            </div>
          ) : null}

          <div className={styles.buttonContainer}>
            <input
              type="submit"
              value="Login"
              className={styles.button}
              onClick={loginHandler}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
