import { useEffect } from "react";
import useClick from "../../../Hooks/useClick";
import { ButtonType } from "../../../Utils/getBoard";
import Dropdown from "../../Common/Dropdown/Dropdown";
import styles from "./Button.module.scss";

interface Props {
  label: string;
  data: ButtonType;
  totalButtons: number;
  setActiveButton: React.Dispatch<React.SetStateAction<number>>;
  boardButtons: Array<number>;
  setBoardButtons: React.Dispatch<React.SetStateAction<number[]>>;
}

function Button(props: Props) {
  const {
    label,
    data,
    totalButtons,
    setActiveButton,
    boardButtons,
    setBoardButtons,
  } = props;

  const { isActive, ref, setIsActive } = useClick(false);

  useEffect(() => {
    if (isActive === false) {
      setActiveButton(-1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div className={styles.mainContainer} ref={ref}>
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.button} ${isActive ? styles.buttonActive : null}`}
          onClick={() => {
            setIsActive(!isActive);
            setActiveButton(data?.id);
          }}>
          {label}
        </button>
      </div>
      <div
        className={
          isActive ? styles.modalContainerActive : styles.modalContainer
        }>
        <div className={styles.bodyContainer}>
          {/* <div className={styles.dropdownContainer}>
            <p className={styles.label}>Profile</p>
            <Dropdown
              value={buttonValue}
              className={styles.dropdown}
              options={[
                { label: "Test", value: "Test" },
                { label: "Test", value: "Test" },
                { label: "Test", value: "Test" },
                { label: "Test", value: "Test" },
              ]}
            />
          </div> */}

          <div className={styles.dropdownContainer}>
            <p className={styles.label}>Default Key</p>
            <Dropdown
              value={boardButtons[data?.id]}
              onChange={(value) => {
                let array = [...boardButtons];
                array[data?.id] = parseInt(value);
                setBoardButtons(array);
              }}
              className={styles.dropdown}
              options={Array(totalButtons)
                .fill(true)
                .map((item, i) => {
                  return {
                    value: i.toString(),
                    label: i.toString(),
                  };
                })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Button;
