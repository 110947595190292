export const bluetoothMessageIds = {
  deviceRestart: 0x10,
  brightness: 0x16,
  animationStatus: 0x18,
  animationType: 0x19,
  bleName: 0x20,
  serialNo: 0x24,
  brookFirmware: 0x2a,
  brookProduct: 0x2b,
  brookUuid: 0x2c,
  firmwareVersion: 0x13,
  primaryLed: 0x14,
  secondaryLed: 0x15,
  wifiSsid: 0x25,
  wifiPassword: 0x26,
  otaDownloadUrl: 0x27,
  wifiConnection: 0x28,
  startWifiOta: 0x29,
  nonReactiveAnimation: 0x2d,
  ota: 0x22,
  mappingButtons: 0x2f,
};
