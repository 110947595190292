import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../../Assets/Close.svg";
import Logo from "../../../Assets/Logo.avif";
import { ReactComponent as MenuIcon } from "../../../Assets/Menu.svg";
import styles from "./Header.module.scss";
import Dropdown from "../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/store";
import { setLocalStorage } from "../../../Utils/setLocalStorage";
import { setLanguage } from "../../../Redux/Language/Reducer";

function Header() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);

  const { language } = useSelector((state: RootState) => state.language);
  console.log("lang", language);

  const getActiveStyles = (route: string) => {
    if (route === location.pathname) {
      return styles.menuActive;
    } else {
      return styles.menu;
    }
  };

  const languageChangeHandler = async (value: string) => {
    console.log("value", value);
    await setLocalStorage("language", value);
    dispatch(setLanguage(value));
  };

  return (
    <header className={styles.mainContainer}>
      <Link to={"/"} className={styles.logoContainer}>
        <img className={styles.logo} src={Logo} alt="logo" />
      </Link>

      <div className={styles.mobileMenuContainer}>
        <button
          className={styles.buttonContainer}
          onClick={() => setMobileMenuActive(true)}>
          <MenuIcon className={styles.icon} />
        </button>

        <div
          className={`${styles.menuContainer} ${mobileMenuActive ? styles.menuContainerActive : null}`}>
          <Link
            className={getActiveStyles("/")}
            to={"/"}
            onClick={() => setMobileMenuActive(false)}>
            Home
          </Link>

          <Link
            className={getActiveStyles("/connection")}
            to={"/connection"}
            onClick={() => setMobileMenuActive(false)}>
            Connection
          </Link>

          <button
            className={styles.closeButtonContainer}
            onClick={() => setMobileMenuActive(false)}>
            <CloseIcon className={styles.icon} />
          </button>
        </div>
      </div>

      <div className={styles.menusContainer}>
        <Link className={getActiveStyles("/")} to={"/"}>
          Home
        </Link>
        <Link className={getActiveStyles("/connection")} to={"/connection"}>
          Connection
        </Link>
        <Dropdown
          className={`${styles.menu} ${styles.dropdown}`}
          value={language}
          options={[
            { label: "English", value: "English" },
            { label: "Japanese 日本語", value: "Japanese 日本語" },
          ]}
          onChange={languageChangeHandler}
        />
      </div>
    </header>
  );
}

export default Header;
