import styles from "./SnackBoxMicro.module.scss";

interface Props {
  activeButton: number;
}

const SnackBoxMicro = (props: Props) => {
  const { activeButton } = props;

  const buttonStyleHandler = (id: number) => {
    if (activeButton === id) {
      return styles.buttonActive;
    } else {
      return styles.button;
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.boardContainer}>
        <div className={styles.board}>
          <svg
            width={"100%"}
            height={"100%"}
            viewBox="0 0 1397 699"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.svgContainer}>
            <g clipPath="url(#clip0_1_2)">
              <path
                d="M0 628.364C0 646.881 7.35584 664.639 20.4493 677.732C33.5427 690.826 51.3012 698.182 69.8182 698.182H1326.55C1345.06 698.182 1362.82 690.826 1375.91 677.732C1389.01 664.639 1396.36 646.881 1396.36 628.364V69.8182C1396.36 51.3012 1389.01 33.5427 1375.91 20.4492C1362.82 7.35577 1345.06 0 1326.55 0H69.8182C51.3012 0 33.5427 7.35577 20.4493 20.4492C7.35584 33.5427 0 51.3012 0 69.8182V628.364Z"
                stroke="#191515"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={styles.border}
              />
              <path
                d="M278.458 141.339C296.789 141.339 314.37 148.622 327.333 161.584C340.295 174.547 347.578 192.128 347.578 210.459C347.578 228.791 340.295 246.372 327.333 259.335C314.37 272.297 296.789 279.579 278.458 279.579C260.126 279.579 242.545 272.297 229.582 259.335C216.62 246.372 209.338 228.791 209.338 210.459C209.338 192.128 216.62 174.547 229.582 161.584C242.545 148.622 260.126 141.339 278.458 141.339Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(0)}
              />
              <path
                d="M447.655 141.339C456.732 141.339 465.72 143.127 474.106 146.601C482.492 150.074 490.112 155.166 496.53 161.584C502.948 168.003 508.04 175.622 511.513 184.008C514.987 192.394 516.775 201.382 516.775 210.459C516.775 219.536 514.987 228.524 511.513 236.91C508.04 245.297 502.948 252.916 496.53 259.335C490.112 265.753 482.492 270.844 474.106 274.318C465.72 277.792 456.732 279.579 447.655 279.579C429.323 279.579 411.742 272.297 398.779 259.335C385.817 246.372 378.535 228.791 378.535 210.459C378.535 192.128 385.817 174.547 398.779 161.584C411.742 148.622 429.323 141.339 447.655 141.339Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(1)}
              />
              <path
                d="M595.556 225.711C613.888 225.711 631.469 232.993 644.431 245.956C657.394 258.918 664.676 276.499 664.676 294.831C664.676 313.163 657.394 330.744 644.431 343.706C631.469 356.669 613.888 363.951 595.556 363.951C577.224 363.951 559.643 356.669 546.681 343.706C533.718 330.744 526.436 313.163 526.436 294.831C526.436 276.499 533.718 258.918 546.681 245.956C559.643 232.993 577.224 225.711 595.556 225.711Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(2)}
              />
              <path
                d="M735.617 322.069C773.791 322.069 804.737 353.015 804.737 391.189C804.737 429.363 773.791 460.309 735.617 460.309C697.443 460.309 666.497 429.363 666.497 391.189C666.497 353.015 697.443 322.069 735.617 322.069Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(10)}
              />
              <path
                d="M673.108 502.624C695.513 502.624 717.001 511.525 732.844 527.368C748.687 543.211 757.588 564.699 757.588 587.104C757.588 609.51 748.687 630.997 732.844 646.84C717.001 662.683 695.513 671.584 673.108 671.584C650.702 671.584 629.214 662.683 613.371 646.84C597.528 630.997 588.628 609.51 588.628 587.104C588.628 564.699 597.528 543.211 613.371 527.368C629.214 511.525 650.702 502.624 673.108 502.624Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(11)}
              />
              <path
                d="M744.99 151.927C763.322 151.927 780.903 159.209 793.866 172.172C806.828 185.135 814.11 202.715 814.11 221.047C814.11 239.379 806.828 256.96 793.866 269.922C780.903 282.885 763.322 290.167 744.99 290.167C726.659 290.167 709.078 282.885 696.115 269.922C683.153 256.96 675.87 239.379 675.87 221.047C675.87 202.715 683.153 185.135 696.115 172.172C709.078 159.209 726.659 151.927 744.99 151.927Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(3)}
              />
              <path
                d="M894.668 237.187C903.745 237.187 912.733 238.975 921.119 242.448C929.505 245.922 937.125 251.013 943.543 257.432C949.962 263.85 955.053 271.47 958.526 279.856C962 288.242 963.788 297.23 963.788 306.307C963.788 315.384 962 324.372 958.526 332.758C955.053 341.144 949.962 348.764 943.543 355.182C937.125 361.601 929.505 366.692 921.119 370.166C912.733 373.639 903.745 375.427 894.668 375.427C876.336 375.427 858.755 368.145 845.793 355.182C832.83 342.22 825.548 324.639 825.548 306.307C825.548 287.975 832.83 270.394 845.793 257.432C858.755 244.469 876.336 237.187 894.668 237.187Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(9)}
              />
              <path
                d="M891.116 64.8911C909.447 64.8911 927.028 72.1734 939.991 85.1359C952.953 98.0984 960.236 115.679 960.236 134.011C960.236 143.088 958.448 152.076 954.974 160.462C951.5 168.848 946.409 176.468 939.991 182.886C933.572 189.305 925.953 194.396 917.567 197.87C909.181 201.343 900.192 203.131 891.116 203.131C882.039 203.131 873.05 201.343 864.664 197.87C856.278 194.396 848.659 189.305 842.24 182.886C835.822 176.468 830.731 168.848 827.257 160.462C823.783 152.076 821.996 143.088 821.996 134.011C821.996 115.679 829.278 98.0984 842.24 85.1359C855.203 72.1734 872.784 64.8911 891.116 64.8911Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(4)}
              />
              <path
                d="M1059.86 64.8911C1078.19 64.8911 1095.77 72.1734 1108.73 85.1359C1121.7 98.0984 1128.98 115.679 1128.98 134.011C1128.98 143.088 1127.19 152.076 1123.72 160.462C1120.24 168.848 1115.15 176.468 1108.73 182.886C1102.32 189.305 1094.7 194.396 1086.31 197.87C1077.92 201.343 1068.94 203.131 1059.86 203.131C1041.53 203.131 1023.95 195.849 1010.98 182.886C998.021 169.924 990.739 152.343 990.739 134.011C990.739 115.679 998.021 98.0984 1010.98 85.1359C1023.95 72.1734 1041.53 64.8911 1059.86 64.8911Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(5)}
              />
              <path
                d="M1060.75 236.299C1069.82 236.299 1078.81 238.087 1087.2 241.56C1095.58 245.034 1103.2 250.125 1109.62 256.544C1116.04 262.962 1121.13 270.582 1124.61 278.968C1128.08 287.354 1129.87 296.342 1129.87 305.419C1129.87 314.496 1128.08 323.484 1124.61 331.87C1121.13 340.256 1116.04 347.876 1109.62 354.294C1103.2 360.712 1095.58 365.804 1087.2 369.277C1078.81 372.751 1069.82 374.539 1060.75 374.539C1042.42 374.539 1024.83 367.257 1011.87 354.294C998.909 341.332 991.627 323.751 991.627 305.419C991.627 287.087 998.909 269.506 1011.87 256.544C1024.83 243.581 1042.42 236.299 1060.75 236.299Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(8)}
              />
              <path
                d="M1225.05 288.698C1243.38 288.698 1260.96 295.981 1273.93 308.943C1286.89 321.906 1294.17 339.487 1294.17 357.818C1294.17 376.15 1286.89 393.731 1273.93 406.693C1260.96 419.656 1243.38 426.938 1225.05 426.938C1206.72 426.938 1189.14 419.656 1176.17 406.693C1163.21 393.731 1155.93 376.15 1155.93 357.818C1155.93 339.487 1163.21 321.906 1176.17 308.943C1189.14 295.981 1206.72 288.698 1225.05 288.698Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(7)}
              />
              <path
                d="M1224.16 119.955C1242.49 119.955 1260.07 127.237 1273.04 140.199C1286 153.162 1293.28 170.743 1293.28 189.075C1293.28 207.406 1286 224.987 1273.04 237.95C1260.07 250.912 1242.49 258.195 1224.16 258.195C1205.83 258.195 1188.25 250.912 1175.29 237.95C1162.32 224.987 1155.04 207.406 1155.04 189.075C1155.04 170.743 1162.32 153.162 1175.29 140.199C1188.25 127.237 1205.83 119.955 1224.16 119.955Z"
                stroke="black"
                strokeWidth={1.39636}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={buttonStyleHandler(6)}
              />
            </g>
            <defs>
              <clipPath id="clip0_1_2">
                <rect width={1396.36} height={698.182} fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SnackBoxMicro;
