import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../../Redux/store";

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { auth } = useSelector((state: RootState) => state.auth);

  if (!auth) {
    return <Navigate to="/admin/login" replace />;
  }

  return element;
};

export default PrivateRoute;
