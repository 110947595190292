import React, { useEffect, useState } from "react";
import styles from "./Contents.module.scss";
import Spinner from "react-spinkit";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { getAllContent } from "../../Redux/Content/Action";
import Loader from "../../Components/Common/Loader/Loader";
import moment from "moment";

function Contents() {
  const dispatch = useDispatch<AppDispatch>();
  const { all_content, all_content_loading, all_content_error } = useSelector(
    (state: RootState) => state.content,
  );

  console.log("all_content", all_content);

  useEffect(() => {
    document.title = "Content | Junkfood";
    dispatch(getAllContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (all_content_loading) {
    return <Loader />;
  }
  return (
    <div className={styles.mainContainer}>
      {all_content_error ? (
        <div className={styles.errorContainer}>
          <p className={styles.error}>Error occured while fetching data</p>
        </div>
      ) : null}
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>All Content</h2>
      </div>
      <div className={styles.contentsContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>Page</div>
          <div className={styles.header}>Codename</div>
          <div className={styles.header}>Created At</div>
          <div className={styles.header}>Updated At</div>
          <div className={styles.header}>Actions</div>
        </div>

        <div className={styles.bodyContainer}>
          {all_content?.data?.map((item: any, i: number) => {
            return (
              <div className={styles.row} key={i}>
                <div className={styles.item}>{item?.page}</div>
                <div className={styles.item}>{item?.codename}</div>
                <div className={styles.item}>
                  {item?.createdAt
                    ? moment(item?.createdAt)?.format("LLL")
                    : "N/A"}
                </div>
                <div className={styles.item}>
                  {item?.updatedAt
                    ? moment(item?.updatedAt)?.format("LLL")
                    : "N/A"}
                </div>
                <div className={styles.item}>{item?.codename}</div>
              </div>
            );
          })}
        </div>

        {/* {all_content?.data?.map((item: any, i: number) => {
          return (
            <div className={styles.content}>
              <h1>{item?.page}</h1>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}

export default Contents;
