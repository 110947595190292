import { useState } from "react";
import Spinner from "react-spinkit";
import { bluetoothCommands } from "../../../Utils/bluetoothCommands";
import { calculateChecksum } from "../../../Utils/calculateChecksum";
import styles from "./Bluetooth.module.scss";

interface Props {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  modalRef: React.MutableRefObject<any>;
  characteristic: BluetoothRemoteGATTCharacteristic | undefined;
  firmwareDownloadUrl: string;
}

function Bluetooth(props: Props) {
  const { modalRef, isActive, characteristic, firmwareDownloadUrl } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const firmwareUpgradeHandler = async () => {
    try {
      setLoading(true);
      let response = await fetch(firmwareDownloadUrl);
      let data = await response.arrayBuffer();

      let fileData = new Uint8Array(data);
      let checksum = calculateChecksum(fileData);

      await characteristic?.writeValue(
        bluetoothCommands.set.otaHeader(fileData.byteLength, checksum),
      );

      let packetSize = 1;
      let offset = 0;
      let chunkSize = 240;

      while (offset < fileData.byteLength) {
        let chunk = Array.from(fileData?.subarray(offset, offset + chunkSize));

        offset += chunk?.length;
        await characteristic?.writeValueWithoutResponse(
          bluetoothCommands.set.otaBody(chunk, packetSize),
        );
        packetSize += 1;
      }
    } catch (error) {
      console.log("firmwareUpgradeHandler error:", error);
    }
  };

  return (
    <div
      className={`${styles.mainContainer} ${isActive ? styles.mainContainerActive : null}`}
      ref={modalRef}>
      <div className={styles.headingContainer}>
        <p className={styles.heading}>Firmware update by bluetooth</p>
      </div>

      <div className={styles.infoContainer}>
        <p className={styles.text}>
          {loading
            ? "Firmware upgrade has started, Please do not disconnect or unplug the board or reload the page, the progress is indicated through boards LED, It will disconnect itself when finished"
            : "Please click upgrade button to start."}
        </p>
      </div>

      {loading ? (
        <div className={styles.loadingContainer}>
          <Spinner name="circle" className={styles.loader} />
        </div>
      ) : null}

      {firmwareDownloadUrl ? (
        <div className={styles.buttonContainer}>
          <button
            className={loading ? styles.buttonDisabled : styles.button}
            onClick={firmwareUpgradeHandler}
            disabled={loading}>
            Upgrade
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Bluetooth;
