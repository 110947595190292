export type ButtonType = {
  id: number;
  code: number;
  index: number;
};

interface BoardType {
  name: string;
  brookUuid: string;
  buttons: Array<ButtonType>;
}

type BoardTypes = Array<BoardType>;

let boardTypes: BoardTypes = [
  {
    name: "SnackBox MICRO",
    brookUuid: "3100170010F1560263E50000A4D66E02",
    buttons: [
      { id: 0, code: 0x01, index: 5 },
      { id: 1, code: 0x02, index: 5 },
      { id: 2, code: 0x04, index: 5 },
      { id: 3, code: 0x08, index: 5 },
      { id: 4, code: 0x10, index: 5 },
      { id: 5, code: 0x20, index: 5 },
      { id: 6, code: 0x40, index: 5 },
      { id: 7, code: 0x80, index: 5 },
      { id: 8, code: 0x01, index: 6 },
      { id: 9, code: 0x02, index: 6 },
      { id: 10, code: 0x04, index: 6 },
      { id: 11, code: 0x08, index: 6 },
    ],
  },

  {
    name: "JF Connect",
    brookUuid: "3100170010F15602B5A60000F6976E02",
    buttons: [
      { id: 0, code: 0x01, index: 5 },
      { id: 1, code: 0x02, index: 5 },
      { id: 2, code: 0x04, index: 5 },
      { id: 3, code: 0x08, index: 5 },
      { id: 4, code: 0x10, index: 5 },
      { id: 5, code: 0x20, index: 5 },
      { id: 6, code: 0x40, index: 5 },
      { id: 7, code: 0x80, index: 5 },
      { id: 8, code: 0x01, index: 6 },
      { id: 9, code: 0x02, index: 6 },
      { id: 10, code: 0x04, index: 6 },
      { id: 11, code: 0x08, index: 6 },
    ],
  },
];

export const getBoard = (brookUuid: string | undefined) => {
  const device = boardTypes?.find((item: BoardType) => item?.brookUuid === brookUuid);
  return device;
};
