import { ActionTypes } from "./Types";

type StateType = {
  auth: any;
  login_loading: boolean;
  login_error: any;

  verify_user_loading: boolean;
  verify_user_error: any;
};

const initialState: StateType = {
  auth: null,
  login_loading: false,
  login_error: null,

  verify_user_loading: true,
  verify_user_error: null,
};

type Action = {
  type: string;
  payload?: any;
};

export const reducer = (
  state: StateType = initialState,
  action: Action,
): StateType => {
  switch (action.type) {
    case ActionTypes.AUTH:
      return {
        ...state,
        auth: action.payload,
        login_loading: false,
        login_error: null,
      };
    case ActionTypes.LOGIN_LOADING:
      return {
        ...state,
        auth: null,
        login_loading: true,
        login_error: null,
      };

    case ActionTypes.LOGIN_ERROR:
      return {
        ...state,
        auth: null,
        login_loading: false,
        login_error: action.payload,
      };

    case ActionTypes.LOGOUT:
      return {
        ...state,
        auth: null,
        login_loading: false,
        login_error: null,
      };

    case ActionTypes.VERIFY_USER:
      return {
        ...state,
        auth: action?.payload,
        verify_user_loading: false,
        verify_user_error: null,
      };

    case ActionTypes.VERIFY_USER_LOADING:
      return {
        ...state,
        auth: null,
        verify_user_loading: true,
        verify_user_error: null,
      };

    case ActionTypes.VERIFY_USER_ERROR:
      return {
        ...state,
        auth: null,
        verify_user_loading: false,
        verify_user_error: action?.payload,
      };

    default:
      return state;
  }
};
