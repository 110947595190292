import { bluetoothCommands } from "./bluetoothCommands";

export const bluetoothReadFunctions = {
  getBrightnessLevel: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(bluetoothCommands.read.brightness());
      }
    } catch (error) {
      console.log("getBrightnessLevel Error", error);
    }
  },

  getAnimationStatus: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(
          bluetoothCommands.read.animationStatus(),
        );
      }
    } catch (error) {
      console.log("getAnimationStatus Error", error);
    }
  },

  getNonReactiveAnimation: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(
          bluetoothCommands.read.nonReactiveAnimation(),
        );
      }
    } catch (error) {
      console.log("getReactiveAnimation Error", error);
    }
  },

  getAnimationType: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(
          bluetoothCommands.read.animationType(),
        );
      }
    } catch (error) {
      console.log("getAnimationType Error", error);
    }
  },

  getSerialNo: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(bluetoothCommands.read.serialNo());
      }
    } catch (error) {
      console.log("getAnimationType Error", error);
    }
  },

  getBrookFirmwareVersion: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(
          bluetoothCommands.read.brookFirmwareVersion(),
        );
      }
    } catch (error) {
      console.log("getBrookFirmwareVersion Error", error);
    }
  },

  getBrookProduct: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(bluetoothCommands.read.brookProduct());
      }
    } catch (error) {
      console.log("getBrookProduct Error", error);
    }
  },

  getBrookUuid: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(bluetoothCommands.read.brookUuid());
      }
    } catch (error) {
      console.log("getBrookUuid Error", error);
    }
  },

  getFirmwareVersion: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(
          bluetoothCommands.read.firmwareVersion(),
        );
      }
    } catch (error) {
      console.log("getBrookFirmwareVersion Error", error);
    }
  },

  getPrimaryLedColor: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(
          bluetoothCommands.read.primaryLedColor(),
        );
      }
    } catch (error) {
      console.log("getPrimaryLedColor Error", error);
    }
  },

  getSecondaryLedColor: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(
          bluetoothCommands.read.secondaryLedColor(),
        );
      }
    } catch (error) {
      console.log("getSecondaryLedColor Error", error);
    }
  },

  getMappingButtons: async (
    characteristic: BluetoothRemoteGATTCharacteristic | undefined,
    profile: number,
  ) => {
    try {
      if (characteristic) {
        await characteristic?.writeValue(
          bluetoothCommands.read.mappingButtons(profile),
        );
      }
    } catch (error) {
      console.log("getMappingButtons Error", error);
    }
  },
};
